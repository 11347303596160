import { STATUS } from '../constants/investment';

export const getStatusDisplayText = (status) => {
  switch (status) {
    case STATUS.AWAITING_CFO_REVIEW:
      return 'Awaiting CFO Review';
    case STATUS.AWAITING_CGO_REVIEW:
      return 'Awaiting CGO Review';
    case STATUS.AWAITING_DEPOSIT:
      return 'Awaiting deposit';
    case STATUS.ACTIVE:
      return 'Active';
    case STATUS.CLOSED:
      return 'Closed';
    case STATUS.LIQUIDATED:
      return 'Liquidated';
    default:
      return status;
  }
};

export const getStatusClasses = (status) => {
  switch (status) {
    case STATUS.AWAITING_DEPOSIT:
      return 'badge-declined';
    case STATUS.AWAITING_CFO_REVIEW:
    case STATUS.AWAITING_CGO_REVIEW:
      return 'badge-processing';
    case STATUS.ACTIVE:
      return 'badge-active';
    case STATUS.CLOSED:
      return 'badge-loan-success';
    default:
      return 'badge-default';
  }
};
