<template>
  <div class="gl-w-full gl-display-flex gl-flex-direction-column mb-4">
    <gl-breadcrumb :items="breadcrumbItems" class="mt-2 mb-6">
      <template #avatar>
        <img
          alt=""
          class="gl-breadcrumb-avatar-tile"
          src="@/assets/logos/breadcrumb-logo.svg"
          width="16"
          height="16"
        />
      </template>
    </gl-breadcrumb>
    <div class="row mb-4 justify-content-between">
      <div class="col-md-8">
        <h2 class="font-weight-bold">
          Welcome back, {{ userData?.firstName }}
        </h2>
        <span class="gl-text-gray-500 f-14"> {{ userData?.role }} </span>
      </div>
      <div class="col-md-4 text-md-right mt-md-0 mt-3">
        <gl-button
          category="primary"
          variant="default"
          buttonTextClasses="gl-display-flex gl-align-items-center"
          @click="isActionQueueOpen = true"
        >
          View action queue
          <gl-badge class="d-none d-lg-block gl-ml-2" size="sm" variant="info">
            {{ actionQueueData?.length }}
            <span
              v-if="
                actionQueueData &&
                (actionQueueData.length > 1 || actionQueueData.length === 0)
              "
              >actions</span
            >
            <span v-else>action</span>
          </gl-badge>
        </gl-button>
      </div>
    </div>

    <div class="border-bottom gl-w-full"></div>

    <div class="d-flex my-4 justify-content-between">
      <h5 class="font-weight-bold">Overview</h5>

      <div>
        <gl-form-select
          id="time-period"
          size="md"
          :disabled="false"
          :multiple="false"
          :selectSize="1"
          value-field="value"
          text-field="label"
          :options="timePeriodOptions"
          value="thisMonth"
          @change="updateTimePeriod"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 mb-md-0 mb-3">
        <gl-card>
          <div class="gl-display-flex gl-flex-direction-column p-2">
            <span class="gl-text-gray-500 f-14">Total amount disbursed</span>
            <div v-if="isLoading === true" class="mt-3">
              <GlSkeletonLoader :equalWidthLines="true" :lines="2" />
            </div>
            <span v-else class="gl-font-size-h1 gl-font-weight-bold mt-2">
              &#8358;
              {{ financialOverview?.totalDisbursement | formatNumber }}</span
            >
          </div>
        </gl-card>
      </div>
      <div class="col-md-4 mb-md-0 mb-3">
        <gl-card>
          <div class="gl-display-flex gl-flex-direction-column p-2">
            <span class="gl-text-gray-500 f-14">
              Total amount to be disbursed
            </span>
            <div v-if="isLoading === true" class="mt-3">
              <GlSkeletonLoader :equalWidthLines="true" :lines="2" />
            </div>
            <span v-else class="gl-font-size-h1 gl-font-weight-bold mt-2">
              &#8358;
              {{ financialOverview?.totalExpectedDisbursement | formatNumber }}
            </span>
          </div>
        </gl-card>
      </div>
      <div class="col-md-4">
        <gl-card>
          <div class="gl-display-flex gl-flex-direction-column p-2">
            <span class="gl-text-gray-500 f-14">
              Total repayments collected
            </span>
            <div v-if="isLoading === true" class="mt-3">
              <GlSkeletonLoader :equalWidthLines="true" :lines="2" />
            </div>
            <span v-else class="gl-font-size-h1 gl-font-weight-bold mt-2">
              &#8358;
              {{ financialOverview?.totalRepayments | formatNumber }}</span
            >
          </div>
        </gl-card>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-4 mb-md-0 mb-3">
        <gl-card>
          <div class="gl-display-flex gl-flex-direction-column p-2">
            <span class="gl-text-gray-500 f-14">Total paid interest</span>
            <div v-if="isLoading === true" class="mt-3">
              <GlSkeletonLoader :equalWidthLines="true" :lines="2" />
            </div>
            <span v-else class="gl-font-size-h1 gl-font-weight-bold mt-2">
              &#8358;
              {{ financialOverview?.totalInterestPaid | formatNumber }}</span
            >
          </div>
        </gl-card>
      </div>
      <div class="col-md-4 mb-md-0 mb-3">
        <gl-card>
          <div class="gl-display-flex gl-flex-direction-column p-2">
            <span class="gl-text-gray-500 f-14">Interest income</span>
            <div v-if="isLoading === true" class="mt-3">
              <GlSkeletonLoader :equalWidthLines="true" :lines="2" />
            </div>
            <span v-else class="gl-font-size-h1 gl-font-weight-bold mt-2">
              &#8358;
              {{ financialOverview?.totalInterestIncome | formatNumber }}</span
            >
          </div>
        </gl-card>
      </div>
      <div class="col-md-4">
        <gl-card>
          <div class="gl-display-flex gl-flex-direction-column p-2">
            <span class="gl-text-gray-500 f-14">Total fees charged</span>
            <div v-if="isLoading === true" class="mt-3">
              <GlSkeletonLoader :equalWidthLines="true" :lines="2" />
            </div>
            <span v-else class="gl-font-size-h1 gl-font-weight-bold mt-2">
              &#8358;
              {{ financialOverview?.totalFeesCharged | formatNumber }}</span
            >
          </div>
        </gl-card>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-8">
        <gl-card>
          <div class="d-flex mb-3 justify-content-between align-items-center">
            <h5 class="gl-text-gray-900 font-weight-bold">Loans</h5>
            <!-- <div>
              <gl-button-group>
                <gl-button
                  buttonTextClasses="gl-display-flex gl-align-items-center"
                  :class="valueDataType ? 'selected' : 'not-selected'"
                  @click="getChartDataByType('value')"
                >
                  Value
                </gl-button>
                <gl-button
                  :class="countDataType ? 'selected' : 'not-selected'"
                  buttonTextClasses="gl-display-flex gl-align-items-center"
                  @click="getChartDataByType('count')"
                >
                  Count
                </gl-button>
              </gl-button-group>
            </div> -->
            <div>
              <gl-form-select
                id="chart-period"
                size="md"
                :disabled="false"
                :multiple="false"
                :selectSize="1"
                value-field="value"
                text-field="label"
                :options="chartPeriodOptions"
                value="Monthly"
                @change="updateLoansChartPeriod"
              />
            </div>
          </div>
          <template>
            <BarChart
              :has-loaded="loanValueDataLoaded"
              :chart-data="loanValuesByGroup"
            />
          </template>
        </gl-card>
      </div>
      <div class="col-md-4">
        <gl-card>
          <div class="d-flex mb-3 justify-content-between align-items-center">
            <h5 class="gl-text-gray-900 font-weight-bold">Performance</h5>
            <!-- <div>
              <gl-form-select
                id="chart-period"
                size="md"
                :disabled="false"
                :multiple="false"
                :selectSize="1"
                value-field="value"
                text-field="label"
                :options="chartPeriodOptions"
                value="monthly"
                @change="updatePerformanceChartPeriod"
              />
            </div> -->
          </div>
          <template>
            <DoughnutChart
              :has-loaded="loanPerformanceLoaded"
              :chart-data="loanPerformanceOverview"
              chartId="loan-performance"
            />
          </template>
        </gl-card>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-4">
        <gl-card>
          <div class="d-flex mb-3 justify-content-between align-items-center">
            <h5 class="gl-text-gray-900 font-weight-bold">
              Risk assets distribution
            </h5>
            <!-- <div>
              <gl-form-select
                id="chart-period"
                size="md"
                :disabled="false"
                :multiple="false"
                :selectSize="1"
                value-field="value"
                text-field="label"
                :options="chartPeriodOptions"
                value="monthly"
                @change="updatePerformanceChartPeriod"
              />
            </div> -->
          </div>
          <template>
            <DoughnutChart
              :has-loaded="riskAssetDataLoaded"
              :chart-data="riskAssetDistribution"
              chartId="risk-asset"
            />
          </template>
        </gl-card>
      </div>
      <div class="col-md-8">
        <gl-card>
          <div class="d-flex mb-3 justify-content-between align-items-center">
            <h5 class="gl-text-gray-900 font-weight-bold">Past due loans</h5>
            <!-- <div>
              <gl-button-group>
                <gl-button
                  buttonTextClasses="gl-display-flex gl-align-items-center"
                  :class="valueDataType ? 'selected' : 'not-selected'"
                  @click="getChartDataByType('value')"
                >
                  Value
                </gl-button>
                <gl-button
                  :class="countDataType ? 'selected' : 'not-selected'"
                  buttonTextClasses="gl-display-flex gl-align-items-center"
                  @click="getChartDataByType('count')"
                >
                  Count
                </gl-button>
              </gl-button-group>
            </div> -->
            <div>
              <gl-form-select
                id="chart-period"
                size="md"
                :disabled="false"
                :multiple="false"
                :selectSize="1"
                value-field="value"
                text-field="label"
                :options="chartPeriodOptions"
                value="Monthly"
                @change="updatePastDueChartPeriod"
              />
            </div>
          </div>
          <template>
            <BarChart
              :has-loaded="pastDueLoansDataLoaded"
              :chart-data="pastDueLoansByGroup"
            />
          </template>
        </gl-card>
      </div>
    </div>

    <gl-drawer
      :open="isActionQueueOpen"
      @close="isActionQueueOpen = false"
      headerHeight=""
      headerSticky
      :zIndex="10"
      variant="default"
    >
      <template #header>
        <h4 class="font-weight-bold">Action queue</h4>
      </template>
      <template v-if="actionQueueData && actionQueueData.length > 0">
        <div
          class="align-items-start px-1 py-3 mx-3 border-bottom d-flex justify-content-between"
          v-for="(action, index) in actionQueueData"
          :key="`action-${index}`"
        >
          <div>
            <div v-html="action?.rawHtmlMessage" class="mb-3 f-14"></div>
            <span class="gl-text-gray-700 f-12">
              {{ action?.reportedAt | formatDayOnly }} &#8226;
              {{ action?.reportedAt | formatTime }}
            </span>
          </div>
          <div v-if="action.loanRequestId">
            <router-link
              :to="`/all-loans/${action.loanRequestId}`"
              class="gl-text-blue-900 f-14"
            >
              Take action
            </router-link>
          </div>
          <div
            v-if="
              action.transactionId && action?.transactionLabel !== 'Withdrawal'
            "
          >
            <router-link
              :to="`/payments/approve-transaction/${action.transactionId}`"
              class="gl-text-blue-900 f-14"
            >
              Take action
            </router-link>
          </div>
          <div
            v-if="
              action.transactionId && action?.transactionLabel === 'Withdrawal'
            "
          >
            <router-link
              :to="`/withdrawal/${action.transactionId}/review-withdrawal`"
              class="gl-text-blue-900 f-14"
            >
              Take action
            </router-link>
          </div>
          <div v-if="action.loanId && action.extensionRequestId">
            <router-link
              :to="`/all-loans/${action.loanId}/review-loan-extension/${action.extensionRequestId}`"
              class="gl-text-blue-900 f-14"
            >
              Take action
            </router-link>
          </div>
          <div v-if="action.businessId">
            <a
              class="gl-text-blue-900 f-14"
              @click="reviewDomiciliationRequest(action)"
            >
              Take action
            </a>
          </div>
        </div>
      </template>
      <div v-else>
        <p class="mt-3 font-italic text-center gl-text-gray-700 f-14">
          You have no pending actions
        </p>
      </div>
    </gl-drawer>

    <gl-modal
      v-model="isCustomSelected"
      modalId="custom-period-id"
      title="Select custom range"
      :actionPrimary="{
        text: 'Apply date range',
        attributes: {
          variant: 'confirm',
          disabled: !!!queryForCustomRange,
        },
      }"
      :actionCancel="{ text: 'Cancel' }"
      :visible="false"
      :noFocusOnShow="false"
      size="sm"
      :scrollable="false"
      no-fade=""
      @primary="applyCustomTimePeriod"
    >
      <div class="mb-custom">
        <gl-daterange-picker
          class="gl-display-flex gl-flex-direction-row"
          startPickerClass="gl-text-black-700"
          endPickerClass="gl-text-black-700"
          @input="dateSelected"
        >
          <!-- <div class="gl-display-flex gl-flex-direction-row gl-w-full">
            <div v-if="daysSelected > -1">{{ daysSelected }} days selected</div>
            <div v-else>No days selected</div>
          </div> -->
        </gl-daterange-picker>
      </div>
    </gl-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import {
  GlBreadcrumb,
  GlFormSelect,
  GlSkeletonLoader,
  GlCard,
  GlButton,
  GlBadge,
  GlModal,
  GlDaterangePicker,
  // GlButtonGroup,
  GlDrawer,
} from '@gitlab/ui';
import { BarChart, DoughnutChart } from '@/components/base';
import { dateHelper } from '@/utils';

const { DATETIMEFORMAT } = dateHelper;

export default {
  name: 'HomeView',
  components: {
    GlFormSelect,
    GlSkeletonLoader,
    GlBreadcrumb,
    GlCard,
    GlButton,
    GlBadge,
    GlModal,
    GlDaterangePicker,
    // GlButtonGroup,
    BarChart,
    DoughnutChart,
    GlDrawer,
  },
  data: () => ({
    financialOverview: null,
    loanPerformanceOverview: null,
    riskAssetDistribution: null,
    loanValuesByGroup: null,
    pastDueLoansByGroup: null,
    isCustomSelected: false,
    queryForCustomRange: null,
    valueDataType: true,
    countDataType: false,
    isActionQueueOpen: false,
    riskAssetDataLoaded: false,
    loanPerformanceLoaded: false,
    loanValueDataLoaded: false,
    pastDueLoansDataLoaded: false,
    actionQueueData: [],
    timeFormat: DATETIMEFORMAT,
    timePeriodOptions: [
      {
        label: 'This year (YTD)',
        value: 'thisYear',
      },
      {
        label: 'This month (MTD)',
        value: 'thisMonth',
      },
      {
        label: 'Last month',
        value: 'lastMonth',
      },
      {
        label: 'Last week',
        value: 'lastWeek',
      },
      {
        label: 'This week',
        value: 'thisWeek',
      },
      {
        label: 'Custom',
        value: 'custom',
      },
    ],
    chartPeriodOptions: [
      {
        label: 'Monthly',
        value: 'Monthly',
      },
      {
        label: 'Weekly',
        value: 'Weekly',
      },
      {
        label: 'Daily',
        value: 'Daily',
      },
    ],
  }),
  computed: {
    userData() {
      return this.$store.getters['auth/currentUser'];
    },
    breadcrumbItems() {
      return [{ text: 'Dashboard', href: '#' }];
    },
    isLoading() {
      return this.$store.getters['loading/show'];
    },
  },
  methods: {
    toggleLoading(isLoading) {
      this.$store.dispatch('loading/toggleLoading', isLoading);
    },

    async getActionQueueData() {
      this.toggleLoading(true);
      const response = await this.$store.dispatch(
        'dashboard/getActionQueueData'
      );
      this.actionQueueData = response;
      this.toggleLoading(false);
    },

    async retrieveFinancialOverview(query) {
      this.toggleLoading(true);
      const response = await this.$store.dispatch(
        'dashboard/getFinancialOverview',
        {
          StartAt: query.startAt,
          EndAt: query.endAt,
        }
      );
      this.financialOverview = response;
      this.toggleLoading(false);
    },

    async retrieveLoanPerformanceOverview() {
      this.loanPerformanceLoaded = false;
      const response = await this.$store.dispatch(
        'dashboard/getLoanPerformanceOverview'
      );

      if (response) {
        let dataNeeded = response.count;
        let keysAndValues = Object.entries(dataNeeded);
        let labels = keysAndValues.map((item) => {
          if (item[0] === 'nonPerforming') {
            item[0] = 'Past due';
          }

          return this.capitalize(item[0]);
        });
        let values = keysAndValues.map((item) => item[1]);

        let datasets = [
          {
            backgroundColor: ['#2B689B', '#F57F6C'],
            data: values,
          },
        ];

        this.loanPerformanceOverview = {
          labels: labels,
          datasets: datasets,
        };
      }

      this.loanPerformanceLoaded = true;
    },

    async retrieveRiskAssetDistribution() {
      this.riskAssetDataLoaded = false;
      const response = await this.$store.dispatch(
        'dashboard/getRiskAssetDistribution'
      );

      if (response) {
        let dataNeeded = response.value;
        let keysAndValues = Object.entries(dataNeeded);
        let labels = keysAndValues.map((item) => item[0].toUpperCase());
        let values = keysAndValues.map((item) => item[1]);

        let datasets = [
          {
            backgroundColor: ['#2B689B', '#52B87A', '#D99530', '#63A6E9'],
            data: values,
          },
        ];

        this.riskAssetDistribution = {
          labels: labels,
          datasets: datasets,
        };
      }

      this.riskAssetDataLoaded = true;
    },

    async retrieveLoanValuesByGroup(grouping) {
      this.loanValueDataLoaded = false;
      const response = await this.$store.dispatch(
        'dashboard/getLoanValuesByGroup',
        grouping
      );

      if (response) {
        let dataNeeded = response.slice(-10);
        let labels = dataNeeded.map((item) =>
          dayjs(item.date).format('DD-MM-YYYY')
        );
        let values = dataNeeded.map((item) => item.value);

        let datasets = [
          {
            label: 'Loans',
            barThickness: 12,
            backgroundColor: '#52B87A',
            data: values,
          },
        ];

        this.loanValuesByGroup = {
          labels,
          datasets,
        };
      }

      this.loanValueDataLoaded = true;
    },

    async retrievePastDueLoansByGroup(grouping) {
      this.pastDueLoansDataLoaded = false;
      const response = await this.$store.dispatch(
        'dashboard/getPastLoanCountByGroup',
        grouping
      );

      if (response) {
        let dataNeeded = response.slice(-10);
        let labels = dataNeeded.map((item) =>
          dayjs(item.date).format('DD-MM-YYYY')
        );
        let values = dataNeeded.map((item) => item.value);

        let datasets = [
          {
            label: 'Past due loans',
            barThickness: 12,
            backgroundColor: '#F57F6C',
            data: values,
          },
        ];

        this.pastDueLoansByGroup = {
          labels,
          datasets,
        };
      }

      this.pastDueLoansDataLoaded = true;
    },

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    formatTimeToDateTime(timePeriod) {
      let splitted = timePeriod.split(' ');
      splitted.splice(1, 0, 'T');
      splitted.push('Z');
      return splitted.join('');
    },

    reviewDomiciliationRequest(action) {
      if (
        action.rawHtmlMessage.includes(
          'Process change of domiciliation request'
        )
      ) {
        this.$store.commit('partner/SET_DOMICILIATION_REQUEST', action);
        this.$router.push(
          `/all-partners/review-domiciliation/${action.businessId}`
        );
      }
    },

    updateTimePeriod(selected) {
      if (selected === 'thisYear') {
        // startDate: first day of the current year
        // endDate: current day of the current month
        let query = {
          startAt: this.formatTimeToDateTime(
            dayjs()
              .subtract(new Date().getMonth(), 'months')
              .startOf('month')
              .utc()
              .format(this.timeFormat)
          ),
          endAt: this.formatTimeToDateTime(
            dayjs().endOf('day').utc().format(this.timeFormat)
          ),
        };
        this.retrieveFinancialOverview(query);
      }
      if (selected === 'thisMonth') {
        // startDate: first day of the current month
        // endDate: current day of the current month
        let query = {
          startAt: this.formatTimeToDateTime(
            dayjs().startOf('month').utc().format(this.timeFormat)
          ),
          endAt: this.formatTimeToDateTime(
            dayjs().endOf('day').utc().format(this.timeFormat)
          ),
        };
        this.retrieveFinancialOverview(query);
      }
      if (selected === 'lastMonth') {
        let lastMonth = dayjs().subtract(1, 'months');
        let query = {
          startAt: this.formatTimeToDateTime(
            lastMonth.startOf('month').utc().format(this.timeFormat)
          ),
          endAt: this.formatTimeToDateTime(
            lastMonth.endOf('month').utc().format(this.timeFormat)
          ),
        };
        this.retrieveFinancialOverview(query);
      }
      if (selected === 'lastWeek') {
        let lastWeek = dayjs().subtract(1, 'weeks');
        let query = {
          startAt: this.formatTimeToDateTime(
            lastWeek.startOf('isoWeek').utc().format(this.timeFormat)
          ),
          endAt: this.formatTimeToDateTime(
            lastWeek.endOf('isoWeek').utc().format(this.timeFormat)
          ),
        };
        this.retrieveFinancialOverview(query);
      }
      if (selected === 'thisWeek') {
        let query = {
          startAt: this.formatTimeToDateTime(
            dayjs().startOf('isoWeek').utc().format(this.timeFormat)
          ),
          endAt: this.formatTimeToDateTime(
            dayjs().endOf('day').utc().format(this.timeFormat)
          ),
        };
        this.retrieveFinancialOverview(query);
      }
      if (selected === 'custom') {
        this.isCustomSelected = true;
      }
    },

    dateSelected(event) {
      this.queryForCustomRange = event;
    },

    applyCustomTimePeriod() {
      let query = {
        startAt: this.formatTimeToDateTime(
          dayjs(this.queryForCustomRange.startDate)
            .startOf('day')
            .utc()
            .format(this.timeFormat)
        ),
        endAt: this.formatTimeToDateTime(
          dayjs(this.queryForCustomRange.endDate)
            .endOf('day')
            .utc()
            .format(this.timeFormat)
        ),
      };
      this.retrieveFinancialOverview(query);
    },

    updatePastDueChartPeriod(event) {
      this.retrievePastDueLoansByGroup(event);
    },

    updateLoansChartPeriod(event) {
      this.retrieveLoanValuesByGroup(event);
    },

    // updatePerformanceChartPeriod(event) {},

    getChartDataByType(type) {
      if (type === 'value') {
        this.countDataType = false;
        this.valueDataType = true;
      } else {
        this.valueDataType = false;
        this.countDataType = true;
      }
    },

    loadCurrentOverview() {
      let query = {
        startAt: this.formatTimeToDateTime(
          dayjs().startOf('month').utc().format(this.timeFormat)
        ),
        endAt: this.formatTimeToDateTime(
          dayjs().endOf('day').utc().format(this.timeFormat)
        ),
      };
      this.getActionQueueData();
      this.retrieveFinancialOverview(query);
      this.retrieveLoanPerformanceOverview();
      this.retrieveRiskAssetDistribution();
      this.retrieveLoanValuesByGroup('Monthly');
      this.retrievePastDueLoansByGroup('Monthly');
    },

    async getAllStates() {
      await this.$store.dispatch('customers/getAllStates');
    },
  },
  beforeMount() {
    this.loadCurrentOverview();
    this.getAllStates();
  },
};
</script>
<style scoped>
.mb-custom {
  margin-bottom: 272px;
}
.not-selected {
  background-color: #d8d6d6;
  color: #666;
  box-shadow: inset 0 0 0 1px #dbdbdb;
  opacity: 1;
}
.selected {
  background-color: #fff;
}
.mx-custom {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
