import apiClient from '@/clients/apiClient';

export const createCorporate = (data) =>
  apiClient.post('corporate-profiles', data);

export const getCorporateList = (
  page = 1,
  pageSize = 10,
  search = '',
  sortBy = '',
  orderBy = ''
) =>
  apiClient.get(
    `corporate-profiles?Page=${page}&PageSize=${pageSize}&Search=${search}&Sort.Sort_By=${sortBy}&Sort.Order_By=${orderBy}`
  );

export const getCorporateProfile = (id) =>
  apiClient.get(`corporate-profiles/${id}`);

export const getCorporateVendors = (
  id,
  page = 1,
  pageSize = 10,
  search = '',
  sortBy = '',
  orderBy = ''
) =>
  apiClient.get(
    `corporate-profiles/${id}/vendors?Page=${page}&PageSize=${pageSize}&Search=${search}&Sort.Sort_By=${sortBy}&Sort.Order_By=${orderBy}`
  );

export const getCorporateFacilities = (
  id,
  page = 1,
  pageSize = 10,
  search = '',
  sortBy = '',
  orderBy = ''
) =>
  apiClient.get(
    `corporate-profiles/${id}/facilities?Page=${page}&PageSize=${pageSize}&Search=${search}&Sort.Sort_By=${sortBy}&Sort.Order_By=${orderBy}`
  );

export const getCorporateDocuments = (
  id,
  page = 1,
  pageSize = 10,
  search = '',
  sortBy = '',
  orderBy = ''
) =>
  apiClient.get(
    `corporate-profiles/${id}/documents?Page=${page}&PageSize=${pageSize}&Search=${search}&Sort.Sort_By=${sortBy}&Sort.Order_By=${orderBy}`
  );
export const editCorporate = (data, id) =>
  apiClient.put(`corporate-profiles/${id}`, data);
export const getCorporateOverview = (id) =>
  apiClient.get(`corporate-profiles/${id}/overview`);
export const verifyCorporate = (id) =>
  apiClient.post(`corporate-profiles/${id}/verify`, {});
export const getAllVerifiedCorporate = () =>
  apiClient.get(`corporate-profiles/verified`);
export const getUnVerifiedCorporate = () =>
  apiClient.get(`corporate-profiles/unverified`);
export const getSimilarUnVerifiedCorporate = (verifiedProfileId) =>
  apiClient.get(`corporate-profiles/similar-unverified/${verifiedProfileId}`);
export const mergeCorporate = (verifiedProfileId,unverifiedProfileIds) =>
  apiClient.post('corporate-profiles/merge', {
    verifiedProfileId,
    unverifiedProfileIds
  });
  export const getSimilarVerifiedCorporate = (unverifiedProfileId,similarityThreshold) =>
    apiClient.get(`corporate-profiles/similar-verified/${unverifiedProfileId}?similarityThreshold=${similarityThreshold}`);
  