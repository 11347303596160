import { companyServices } from '@/services';

const {
  getCompanyList,
  getCompanyProfile,
  getCompanyDocuments,
  getNewCompanies,
  getChangedCompanies,
  getCompanyLoanRequests,
  getCompaniesCount,
  createCompany,
  verifyOrReverifyCompany,
  uploadCompanyDocument,
  getCompanyWallet,
  editCompany,
  updateCompanyLogo,
  getUnpaginatedBusinessList,
} = companyServices;

export default {
  namespaced: true,
  state: () => ({
    companyList: [],
    newCompanies: [],
    changedCompanies: [],
    newCompaniesCount: 0,
    changedCompaniesCount: 0,
    currentCompany: null,
  }),
  getters: {
    companyList: ({ companyList }) => companyList,
    companiesPendingApproval: ({ companiesPendingApproval }) =>
      companiesPendingApproval,
    newCompanies: ({ newCompanies }) => newCompanies,
    newCompaniesCount: ({ newCompaniesCount }) => newCompaniesCount,
    changedCompanies: ({ changedCompanies }) => changedCompanies,
    changedCompaniesCount: ({ changedCompaniesCount }) => changedCompaniesCount,
    currentCompany: ({ currentCompany }) => currentCompany,
  },
  mutations: {
    SET_COMPANY_LIST: (state, data) => {
      state.companyList = data;
    },
    SET_COMPANIES_PENDING_APPROVAL: (state, data) => {
      state.companiesPendingApproval = data;
    },
    SET_NEW_COMPANIES: (state, data) => {
      state.newCompanies = data;
    },
    SET_CHANGED_COMPANIES: (state, data) => {
      state.changedCompanies = data;
    },
    SET_NEW_COMPANIES_COUNT: (state, data) => {
      state.newCompaniesCount = data;
    },
    SET_CHANGED_COMPANIES_COUNT: (state, data) => {
      state.changedCompaniesCount = data;
    },
    SET_CURRENT_COMPANY: (state, data) => {
      state.currentCompany = data;
    },
  },
  actions: {
    async getCompanyList({ commit, dispatch }, query) {
      try {
        const { data } = await getCompanyList(
          query?.page || 1,
          query?.pageSize || 10,
          query?.search || '',
          query?.isRegistered || '',
          query?.isPlatformMerchant || '',
          query?.status || '',
          query?.startAt || '',
          query?.endAt || '',
          query?.sortBy || '',
          query?.orderBy || ''
        );

        commit('SET_COMPANY_LIST', data.data);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getNewCompanies({ commit, dispatch }, query) {
      try {
        const { data } = await getNewCompanies(
          query?.page || 1,
          query?.pageSize || 10,
          query?.search || ''
        );

        commit('SET_NEW_COMPANIES', data);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getChangedCompanies({ commit, dispatch }, query) {
      try {
        const { data } = await getChangedCompanies(
          query?.page || 1,
          query?.pageSize || 10,
          query?.search || ''
        );

        commit('SET_CHANGED_COMPANIES', data);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCompaniesCount({ commit, dispatch }) {
      try {
        const { data } = await getCompaniesCount();

        commit('SET_NEW_COMPANIES_COUNT', data.new);
        commit('SET_CHANGED_COMPANIES_COUNT', data.changed);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCompanyProfile({ dispatch }, id) {
      try {
        const { data } = await getCompanyProfile(id);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCompaniesLite({ dispatch }) {
      try {
        const { data } = await getUnpaginatedBusinessList();

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCompanyDocuments({ dispatch }, id) {
      try {
        const { data } = await getCompanyDocuments(id);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCompanyLoanRequests({ dispatch }, id) {
      try {
        const { data } = await getCompanyLoanRequests(id);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCompanyWallet({ dispatch }, id) {
      try {
        const { data } = await getCompanyWallet(id);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async verifyOrReverifyCompany({ dispatch }, payload) {
      try {
        const data = await verifyOrReverifyCompany(
          payload.businessProfileId,
          payload.verificationType
        );

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            {
              message: `Company ${
                payload.verificationType === 'verify'
                  ? 'verified'
                  : 'reverified'
              }`,
              type: 'success',
            },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async uploadCompanyDocument({ dispatch }, payload) {
      try {
        const data = await uploadCompanyDocument(payload);

        if (data.status === 201) {
          dispatch(
            'notifications/displayNotification',
            {
              message: 'Document upload successful',
              type: 'success',
            },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async createCompany({ dispatch }, companyData) {
      try {
        const data = await createCompany(companyData);

        if (data.status === 201) {
          dispatch(
            'notifications/displayNotification',
            { message: `Added ${data?.data?.businessName}`, type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async editCompanyDetails({ dispatch }, companyData) {
      try {
        const data = await editCompany(companyData);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: `Updated company details`, type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async updateLogo({ dispatch }, pictureData) {
      try {
        const data = await updateCompanyLogo(pictureData);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: `Updated company logo`, type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
  },
};
