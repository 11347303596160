export const STATUS = {
  ACTIVE: 'Active',
  APPROVED: 'Approved',
  AWAITING_CGO_REVIEW: 'AwaitingCGOReview',
  AWAITING_CFO_REVIEW: 'AwaitingCFOReview',
  AWAITING_DEPOSIT: 'AwaitingDeposit',
  CLOSED: 'Closed',
  LIQUIDATED: 'Liquidated',
};

export const documentTypes = [
  { label: 'Select Document', value: null },
  { label: 'Investment Certificate', value: 'InvestmentCertificate' },
  { label: 'Investment Indicative Offer', value: 'InvestmentIndicativeOffer' },
  { label: 'Others', value: 'Others' },
];
