import { saveAs } from 'file-saver';
import apiClient from '@/clients/apiClient';

export const getTransactions = (
  type,
  page = 1,
  pageSize = 50,
  search = '',
  transactionType = '',
  fromAmount = '',
  toAmount = '',
  status = '',
  startAt = '',
  endAt = '',
  sortBy = '',
  orderBy = ''
) =>
  apiClient.get(
    `transactions/${type}?Page=${page}&PageSize=${pageSize}&Search=${search}&TransactionType=${transactionType}&FromAmount=${fromAmount}&ToAmount=${toAmount}&Status=${status}&StartAt=${startAt}&EndAt=${endAt}&Sort.Sort_By=${sortBy}&Sort.Order_By=${orderBy}`
  );

export const getTransactionDetails = (transactionId) =>
  apiClient.get(`transactions/${transactionId}`);

export const exportPaymentLogs = (EndAt, Format, StartAt, Search, logType) =>
  apiClient
    .get(
      `transactions/${logType}/export?EndAt=${EndAt}&Format=${Format}&StartAt=${StartAt}&Search=${Search}`,
      { responseType: 'blob' }
    )
    .then((response) => {
      let fileFormat = Format === 'Pdf' ? 'pdf' : 'xlsx';
      saveAs(response.data, `payment-logs-${EndAt}.${fileFormat}`);
    });

export const initiateWithdrawal = (walletId, payload) =>
  apiClient.post(`wallets/${walletId}/internal/initiate-withdrawal`, payload);

export const approveOrDeclineTransaction = (payload) =>
  apiClient.post(`transactions/${payload.transactionId}/${payload.type}`, {});
