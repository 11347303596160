import apiClient from '@/clients/apiClient';
import { saveAs } from 'file-saver';

export const createPartner = (payload) =>
  apiClient.post('integration-partners', payload);

export const updatePartner = (payload, partnerId) =>
  apiClient.put(`integration-partners/${partnerId}`, payload);

export const getPartners = (
  page = 1,
  pageSize = 10,
  search = '',
  filterOptions
) =>
  apiClient.get(
    `integration-partners?Page=${page}&PageSize=${pageSize}&Search=${search}&IsEnabled=${
      filterOptions?.isEnabled || ''
    }&Sort.Sort_By=${filterOptions?.sortBy || ''}&Sort.Order_By=${
      filterOptions?.orderBy || ''
    }`
  );
export const getPartner = (partnerId) =>
  apiClient.get(`integration-partners/${partnerId}`);

export const reactivateOrDeactivatePartner = (status, partnerId) =>
  apiClient.post(`integration-partners/${partnerId}/${status}`);

export const regenerateSecretKey = (partnerId) =>
  apiClient.post(`integration-partners/${partnerId}/regenerate-secret`);

export const acceptDomiciliationRequest = (businessId, reason, status) =>
  apiClient.post(`businesses/${businessId}/process-change-of-domiciliation`, {
    reason,
    status,
  });

export const preApproveDomiciliationRequest = (businessId, reason, status) =>
  apiClient.post(`businesses/${businessId}/send-change-of-domiciliation-request-to-cro`,
    {
      reason,
      status,
    }
  );

export const exportPartnersList = (type) =>
  apiClient
    .get(`integration-partners/export/${type}`, { responseType: 'blob' })
    .then((response) => {
      saveAs(response.data, `partners.${type}`);
    });

export const exportPlatformLoans = (data) =>
  apiClient
    .post(`integration-partners/platform-loans/export`, data, {
      responseType: 'blob',
    })
    .then((response) => {
      let fileFormat = data.Format === 'Pdf' ? 'pdf' : 'xlsx';
      saveAs(response.data, `platform-loans.${fileFormat}`);
    });
