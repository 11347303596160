import { customerServices } from '@/services';
import {
  getCustomerProfile,
  getCustomersCount,
  suspendCustomer,
} from '@/services/customerServices';

const {
  getCustomerList,
  createCustomer,
  updateCustomer,
  getCustomersNeedingVerification,
  getCustomerLoans,
  getCustomerBusinesses,
  getUnpaginatedCustomerList,
  reactivateCustomer,
  verifyCustomerBVN,
  forceResetUserPassword,
  uploadCustomerDocument,
  getStates,
} = customerServices;

export default {
  namespaced: true,
  state: () => ({
    customerList: [],
    customerLoans: [],
    customerBusinesses: [],
    customersPendingVerification: [],
    customersPendingVerificationCount: 0,
    allStates: [],
  }),
  getters: {
    customerList: ({ customerList }) => customerList,
    customerLoans: ({ customerLoans }) => customerLoans,
    customersPendingVerification: ({ customersPendingVerification }) =>
      customersPendingVerification,
    customersPendingVerificationCount: ({
      customersPendingVerificationCount,
    }) => customersPendingVerificationCount,
    allStates: ({ allStates }) => allStates,
  },
  mutations: {
    SET_CUSTOMER_LIST: (state, data) => {
      state.customerList = data;
    },
    SET_CUSTOMER_LOANS: (state, data) => {
      state.customerLoans = data;
    },
    SET_UNVERIFIED_CUSTOMERS: (state, data) => {
      state.customersPendingVerification = data;
    },
    SET_UNVERIFIED_CUSTOMERS_COUNT: (state, data) => {
      state.customersPendingVerificationCount = data;
    },
    SET_ALL_STATES: (state, data) => {
      state.allStates = data;
    },
  },
  actions: {
    async getCustomerList({ commit, dispatch }, query) {
      try {
        const { data } = await getCustomerList(
          query?.page || 1,
          query?.pageSize || 10,
          query?.search || '',
          query?.isVerified || '',
          query?.sortBy || '',
          query?.orderBy || ''
        );

        commit('SET_CUSTOMER_LIST', data.data);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getUnpaginatedCustomerList({ dispatch }, query) {
      try {
        const { data } = await getUnpaginatedCustomerList(query?.search || '');

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCustomersCount({ commit, dispatch }) {
      try {
        const { data } = await getCustomersCount();

        const { awaitingVerification } = data;
        commit('SET_UNVERIFIED_CUSTOMERS_COUNT', awaitingVerification);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCustomersPendingApproval({ commit, dispatch }, query) {
      try {
        const { data } = await getCustomersNeedingVerification(
          query?.search || ''
        );

        commit('SET_UNVERIFIED_CUSTOMERS', data);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCustomerProfile({ dispatch }, id) {
      try {
        const { data } = await getCustomerProfile(id);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCustomerLoans({ dispatch }, id) {
      try {
        const { data } = await getCustomerLoans(id);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCustomerBusinesses({ dispatch }, id) {
      try {
        const { data } = await getCustomerBusinesses(id);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async createCustomer({ dispatch }, customer) {
      try {
        const response = await createCustomer(customer);
        if (response.status === 201) {
          dispatch(
            'notifications/displayNotification',
            {
              message: `Added ${response.data?.emailAddress}`,
              type: 'success',
            },
            { root: true }
          );
        }

        return response.data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async updateCustomer({ dispatch }, body) {
      try {
        const response = await updateCustomer(body?.customer, body?.id);

        if (response.status === 200) {
          dispatch(
            'notifications/displayNotification',
            {
              message: `Updated ${response?.data?.emailAddress}`,
              type: 'success',
            },
            { root: true }
          );
        }

        return response?.data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async suspendCustomer({ dispatch }, userId) {
      try {
        const data = await suspendCustomer(userId);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'User suspended', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;

        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async reactivateCustomer({ dispatch }, userId) {
      try {
        const data = await reactivateCustomer(userId);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'User activated', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;

        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async verifyCustomerBVN({ dispatch }, userId) {
      try {
        const data = await verifyCustomerBVN(userId);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'User verified', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;

        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async forceResetUserPassword({ dispatch }, userId) {
      try {
        const data = await forceResetUserPassword(userId);

        if (data.status === 204) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Password reset request successful', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;

        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async getAllStates({ commit, dispatch }) {
      try {
        const { data } = await getStates();
        commit('SET_ALL_STATES', data);
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'error' },
          { root: true }
        );
      }
    },

    async uploadCustomerDocument({ dispatch }, payload) {
      try {
        const data = await uploadCustomerDocument(payload);

        if (data.status === 200 || data.status === 201) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Document upload successful', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
  },
};
