import apiClient from '@/clients/apiClient';

export const signIn = (data) => apiClient.post('auth/login', data);

export const requestReset = (data) =>
  apiClient.post('auth/request-reset', data);

export const resetPassword = (data) =>
  apiClient.post('auth/reset-password', data);

export const logUserOut = (data) => apiClient.post('auth/logout', data);
