import { saveAs } from 'file-saver';
import apiClient from '@/clients/apiClient';

export const getDocuments = (investmentId) =>
  apiClient.get(`investments/${investmentId}/documents`);

export const getInvestments = (
  page = 1,
  pageSize = 10,
  search = '',
  fromAmount = '',
  toAmount = '',
  maturityStartAt = '',
  maturityEndAt = '',
  createdStartAt = '',
  createdEndAt = '',
  type = '',
  currency = '',
  status = '',
  orderBy = '',
  sortBy = ''
) =>
  apiClient.get(
    `investments/?Page=${page}&PageSize=${pageSize}&Search=${search}&FromAmount=${fromAmount}&ToAmount=${toAmount}&MaturityStartAt=${maturityStartAt}&MaturityEndAt=${maturityEndAt}&CreatedStartAt=${createdStartAt}&CreatedEndAt=${createdEndAt}&Type=${type}&Currency=${currency}&Status=${status}&Sort.Order_By=${orderBy}&Sort.Sort_By=${sortBy}`
  );

export const createInvestment = (payload) =>
  apiClient.post(`investments/${payload.currency}`, payload);

export const getInvestmentDetails = (investmentId) =>
  apiClient.get(`investments/${investmentId}`);

export const getInvestmentPayouts = (investmentId) =>
  apiClient.get(`investments/${investmentId}/payouts`);

export const getInvestmentTransactions = (investmentId) =>
  apiClient.get(`investments/${investmentId}/transactions`);

export const uploadDocument = (investmentId, payload) =>
  apiClient.post(`investments/${investmentId}/documents`, payload);

export const deleteDocument = (payload) =>
  apiClient.delete(
    `investments/${payload.investmentId}/documents/${payload.documentId}`
  );

export const approveForDeposit = (investmentId) =>
  apiClient.post(`investments/${investmentId}/approve`);

export const activateInvestment = (investmentId) =>
  apiClient.post(`investments/${investmentId}/activate`);

export const addBankDeposit = (payload) =>
  apiClient.post(
    `investments/${payload.investmentId}/make-bank-deposit`,
    payload
  );

export const addWalletDeposit = (payload) =>
  apiClient.post(
    `investments/${payload.investmentId}/make-wallet-deposit`,
    payload
  );

export const liquidateInvestment = (payload, type) =>
  apiClient.post(`investments/${payload.investmentId}/${type}`, payload);

export const investmentLogs = (investmentId) =>
  apiClient.get(`investments/${investmentId}/logs`);

export const addNoteToInvestmentLog = (investmentId, payload) =>
  apiClient.post(`investments/${investmentId}/notes`, payload);

export const getPayoutDetails = (investmentId, payoutId) =>
  apiClient.get(`investments/${investmentId}/payouts/${payoutId}`);

export const exportInvestmentTransactionHistory = (
  EndAt,
  Format,
  StartAt,
  investmentId
) => {
  const payload = { startAt: StartAt, endAt: EndAt, format: Format };
  return apiClient
    .post(`investments/${investmentId}/generate-statement`, payload, {
      responseType: 'blob',
    })
    .then((response) => {
      let fileFormat = Format === 'Pdf' ? 'pdf' : 'xlsx';
      saveAs(response.data, `Investments-transactions-${EndAt}.${fileFormat}`);
    });
};

export const generateInvestmentCertificate = (investmentId) => {
  const dateConstant = new Date().getTime();
  return apiClient
    .get(`investments/${investmentId}/generate-certificate`, {
      responseType: 'blob',
    })
    .then((response) => {
      saveAs(response.data, `Investments-certificate-${dateConstant}.pdf`);
    });
};
