import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import { VueEditor } from 'vue2-editor';
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';
import VueSimpleContextMenu from 'vue-simple-context-menu';

import setConfigs from '@gitlab/ui/dist/config';
import { GlToast } from '@gitlab/ui';
// import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import {
  formatDate,
  formatPhone,
  censorBVN,
  padValue,
  isNullOrEmpty,
  humanReadableDate,
  formatDay,
  formatNumber,
  formatTime,
  formatDayOnly,
  getCompanyStatusDisplayText,
  humanReadableDateWithoutTime,
  monthInThePast,
  getPeriodDifference,
  formatPascalCaseText,
} from './plugins/filters';
import router from './router';
import { store } from './store';

setConfigs();

Vue.config.productionTip = false;

Vue.use(GlToast);
Vue.use(VueAxios, axios);
Vue.use(VueEditor);

Vue.filter('formatDate', formatDate);
Vue.filter('formatPhone', formatPhone);
Vue.filter('censorBVN', censorBVN);
Vue.filter('padValue', padValue);
Vue.filter('isNullOrEmpty', isNullOrEmpty);
Vue.filter('humanReadableDate', humanReadableDate);
Vue.filter('humanReadableDateWithoutTime', humanReadableDateWithoutTime);
Vue.filter('formatDay', formatDay);
Vue.filter('formatNumber', formatNumber);
Vue.filter('formatTime', formatTime);
Vue.filter('monthInThePast', monthInThePast);
Vue.filter('formatDayOnly', formatDayOnly);
Vue.filter('getCompanyStatusDisplayText', getCompanyStatusDisplayText);
Vue.filter('getPeriodDifference', getPeriodDifference);
Vue.filter('formatPascalCaseText', formatPascalCaseText);

Vue.component('vue-simple-context-menu', VueSimpleContextMenu);

const isProduction = process.env.VUE_APP_ENV === 'production';

Sentry.init({
  Vue,
  debug: !isProduction,
  dsn:
    process.env.VUE_APP_SENTRY_DSN ||
    'https://f5f26e12225140cb9b41936535bd2506@o4504126528487424.ingest.sentry.io/4504126533599232',
  environment: process.env.VUE_APP_ENV || 'development',
  // Uncomment to track routing, also remember import and add website's URL
  // in tracingOrigins array
  // integrations: [
  //   new BrowserTracing({
  //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //     tracingOrigins: ['localhost', /^\//],
  //   }),
  // ],
  logErrors: isProduction,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
