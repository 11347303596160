<template>
  <b-toast
    :id="message"
    auto-hide-delay="7500"
    toaster="b-toaster-bottom-right"
    body-class="p-2"
    header-class="d-none"
    :variant="type"
    v-model="show"
    solid
  >
    <template #default>
      <div class="font-weight-bold">
        {{ message }}
      </div>
    </template>
  </b-toast>
</template>

<script>
export default {
  name: 'NotificationComponent',
  components: {},
  computed: {
    message() {
      return this.$store.getters['notifications/message'];
    },
    show() {
      return this.$store.state.notifications.show;
    },
    type() {
      return this.$store.getters['notifications/type'];
    },
  },
  methods: {
    dismiss() {
      this.$store.dispatch('notifications/dismiss');
    },
  },
};
</script>

<style scoped></style>
