import { partnerServices } from '@/services';

const { acceptDomiciliationRequest, preApproveDomiciliationRequest} = partnerServices;
export default {
  namespaced: true,
  state: () => ({
    partners: [],
    domiciliationRequest: null,
  }),

  getters: {
    partners: ({ partners }) => partners,
    domiciliationRequest: ({ domiciliationRequest }) => domiciliationRequest,
  },

  mutations: {
    SET_DOMICILIATION_REQUEST: (state, data) => {
      state.domiciliationRequest = data;
    },
  },

  actions: {
    async acceptDomiciliationRequest({ dispatch }, payload) {
      try {
        const response = await acceptDomiciliationRequest(
          payload.businessId,
          payload.reason,
          payload.status
        );

        if (response.status === 200) {
          const { data } = response;
          if (data.message) {
            dispatch(
              'notifications/displayNotification',
              { message: data.message, type: 'success' },
              { root: true }
            );
          } else {
            dispatch(
              'notifications/displayNotification',
              {
                message:
                  payload.status === 'Approved'
                    ? ' Domiciliation Request approved'
                    : 'Domiciliation Request declined',
                type: 'success',
              },
              { root: true }
            );
          }
        }
        return response;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async preApproveDomiciliationRequest({ dispatch }, payload) {
      try {
        const response = await preApproveDomiciliationRequest(
          payload.businessId,
          payload.reason,
          payload.status
        );
        if (response.status === 200) {
          const { data } = response;
          if (data.message) {
            dispatch(
              'notifications/displayNotification',
              { message: data.message, type: 'success' },
              { root: true }
            );
          } else {
            dispatch(
              'notifications/displayNotification',
              {
                message:
                  payload.status === 'Approved'
                    ? ' Domiciliation Request approved'
                    : 'Domiciliation Request declined',
                type: 'success',
              },
              { root: true }
            );
          }
        }
        return response;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
  },
};
