export default {
  namespaced: true,
  state: {
    show: false,
  },
  getters: {
    show: ({ show }) => show,
  },
  mutations: {
    SET_SHOW(state, payload) {
      state.show = payload;
    },
  },
  actions: {
    toggleLoading({ commit }, payload) {
      commit('SET_SHOW', payload);
    },
  },
};
