import { saveAs } from 'file-saver';
import apiClient from '@/clients/apiClient';

export const getWalletTransactionHistory = (walletId, search = '') =>
  apiClient.get(`wallets/${walletId}/transactions?Search=${search}`);

export const exportWalletTransactionHistory = (
  EndAt,
  Format,
  StartAt,
  Search,
  walletId
) =>
  apiClient
    .get(
      `wallets/${walletId}/transactions/export?EndAt=${EndAt}&Format=${Format}&StartAt=${StartAt}&Search=${Search}`,
      { responseType: 'blob' }
    )
    .then((response) => {
      let fileFormat = Format === 'Pdf' ? 'pdf' : 'xlsx';
      saveAs(response.data, `wallet-transactions-${EndAt}.${fileFormat}`);
    });

// Credit: "deposit", Debit: "withdrawal-request"
export const addWalletTransaction = (payload) =>
  apiClient.post(
    `wallets/${payload.walletId}/internal/${payload.type}`,
    payload
  );
