<template>
  <gl-modal 
    v-model="showDialog" 
    modalId="session-timeout-modal" 
    :title="dialogTitle" 
    :actionPrimary="{
      text: 'Resume Session',
      attributes: {
        variant: 'confirm',
      },
    }" 
    :actionCancel="{ text: 'Logout' }" 
    @primary="resumeSession" 
    @cancel="logout"
    @hidden="onHidden"
  >
    <p>{{ dialogMessage }}</p>
    <p v-if="showCountdown">Session will end in {{ countdown }} seconds.</p>
  </gl-modal>
</template>

<script>
import { GlModal } from '@gitlab/ui';

export default {
  name: 'SessionTimeoutDialog',
  components: {
    GlModal,
  },
  data() {
    return {
      showDialog: false,
      dialogTitle: 'Session Timeout',
      dialogMessage: 'Your session has been inactive for 2 minutes and 30 seconds.',
      showCountdown: false,
      countdown: 30,
      countdownInterval: null,
      timeoutId: null,
    };
  },
  methods: {
    startDialog() {
      this.showDialog = true;
      this.showCountdown = true; // Show countdown immediately
      this.countdown = 30;
      clearTimeout(this.timeoutId);
      clearInterval(this.countdownInterval);
      this.startCountdown();
    },
    startCountdown() {
      this.countdownInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          this.logout();
        }
      }, 1000);
    },
    resumeSession() {
      this.showDialog = false;
      this.resetCountdown();
      this.$emit('session-resumed');
    },
    logout() {
      this.showDialog = false;
      this.resetCountdown();
      this.$store.dispatch('auth/logout');
    },
    resetCountdown() {
      this.showCountdown = false;
      clearTimeout(this.timeoutId);
      clearInterval(this.countdownInterval);
      this.countdown = 30;
    },
    onHidden() {
      this.resetCountdown();
    },
  },
};
</script>

