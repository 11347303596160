export default {
  namespaced: true,
  state: () => ({
    message: null,
    type: 'info',
    show: false,
  }),
  getters: {
    message: ({ message }) => message,
    show: ({ show }) => show,
    type: ({ type }) => type,
  },
  mutations: {
    SET_MESSAGE(state, payload) {
      state.message = payload;
    },
    SET_SHOW(state, payload) {
      state.show = payload;
    },
    SET_TYPE(state, payload) {
      state.type = payload;
    },
  },
  actions: {
    dismiss({ commit }, onDismiss) {
      commit('SET_SHOW', false);
      if (onDismiss) {
        onDismiss();
      }
    },
    displayNotification({ commit, getters }, { message, type, onDismiss }) {
      commit('SET_MESSAGE', message);
      commit('SET_TYPE', type);
      commit('SET_SHOW', true);
      setTimeout(() => {
        if (getters.show) {
          commit('SET_SHOW', false);
        }
        if (onDismiss) {
          onDismiss();
        }
        commit('SET_MESSAGE', '');
      }, 7500);
    },
  },
};
