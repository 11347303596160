import { bankServices } from '@/services';

const { getBanks, verifyBankAccount } = bankServices;

export default {
  namespaced: true,
  state: () => ({
    banks: [],
  }),

  getters: {
    getBanks: ({ banks }) => banks,
  },

  mutations: {
    SET_BANKS: (state, data) => {
      state.banks = data;
    },
  },

  actions: {
    async getBanksList({ commit, dispatch }) {
      try {
        const { data } = await getBanks();
        commit('SET_BANKS', data);
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'error' },
          { root: true }
        );
      }
    },
    async verifyAccountInformation({ dispatch }, payload) {
      try {
        let { data } = await verifyBankAccount(payload);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'error' },
          { root: true }
        );
      }
    },
  },
};
