import { dashboardServices } from '@/services';

const {
  getFinancialOverview,
  getLoanPerformanceOverview,
  getRiskAssetDistribution,
  getLoanValuesByGroup,
  getPastLoanCountByGroup,
  getActionQueueData,
} = dashboardServices;

export default {
  namespaced: true,
  state: () => ({
    financialOverview: {},
    loanPerformanceOverview: {},
  }),
  getters: {},
  mutations: {},
  actions: {
    async getFinancialOverview({ dispatch }, query) {
      try {
        const { data } = await getFinancialOverview(
          query.StartAt || '',
          query.EndAt || ''
        );

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getLoanPerformanceOverview({ dispatch }) {
      try {
        const { data } = await getLoanPerformanceOverview();

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getRiskAssetDistribution({ dispatch }) {
      try {
        const { data } = await getRiskAssetDistribution();

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getLoanValuesByGroup({ dispatch }, grouping) {
      try {
        const { data } = await getLoanValuesByGroup(grouping || '');

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getPastLoanCountByGroup({ dispatch }, grouping) {
      try {
        const { data } = await getPastLoanCountByGroup(grouping || '');

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getActionQueueData({ dispatch }) {
      try {
        const { data } = await getActionQueueData();

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
  },
};
