<template>
  <div id="app">
    <base-loader />
    <notification />
    <SessionTimeoutDialog ref="sessionTimeoutDialog" @session-resumed="handleSessionResumed" />
    <router-view />
  </div>
</template>

<script>
import { Notification, BaseLoader } from '@/components/base';
import SessionTimeoutDialog from '@/components/SessionTimeoutDialog.vue';

export default {
  components: {
    Notification,
    BaseLoader,
    SessionTimeoutDialog,
  },
  data() {
    return {
      sessionCheckInterval: null,
    };
  },
  mounted() {
    if (this.$store.getters['auth/isLoggedIn']) {
      this.startSessionCheck();
    }
    window.addEventListener('mousemove', this.handleUserActivity);
    window.addEventListener('keydown', this.handleUserActivity);
  },
  beforeDestroy() {
    this.stopSessionCheck();
    window.removeEventListener('mousemove', this.handleUserActivity);
    window.removeEventListener('keydown', this.handleUserActivity);
  },
  methods: {
    startSessionCheck() {
      this.sessionCheckInterval = setInterval(() => {
        const inactiveTime = Date.now() - this.$store.state.auth.lastActivity;
        if (inactiveTime >= 150000 && this.$refs.sessionTimeoutDialog.countdown === 30) { // 2 minutes 30 seconds (150000 ms)
          this.$refs.sessionTimeoutDialog.startDialog();
        }
      }, 10000); // Check every 10 seconds
    },
    stopSessionCheck() {
      clearInterval(this.sessionCheckInterval);
    },
    handleUserActivity() {
      if (this.$store.getters['auth/isLoggedIn']) {
        this.$store.dispatch('auth/updateLastActivity');
      }
    },
    handleSessionResumed() {
      this.handleUserActivity();
    },
  },
  watch: {
    '$store.getters["auth/isLoggedIn"]'(newValue) {
      if (newValue) {
        this.startSessionCheck();
      } else {
        this.stopSessionCheck();
      }
    },
  },
};
</script>

<style lang="scss">
@import './index.scss';
</style>
