<template>
  <transition name="fade">
    <div v-if="showLoader" class="loader">
      <gl-loading-icon
        label="Loading..."
        size="xl"
        color="light"
        :inline="false"
      />
      <p>Loading...</p>
    </div>
  </transition>
</template>

<script>
import { GlLoadingIcon } from '@gitlab/ui';

export default {
  name: 'BaseLoader',
  components: {
    GlLoadingIcon,
  },
  computed: {
    showLoader() {
      return this.$store.getters['loading/show'];
    },
  },
};
</script>

<style scoped>
.loader {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.64);
}

p {
  margin-top: 10px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
</style>
