import { walletServices } from '@/services';

const {
  getWalletTransactionHistory,
  exportWalletTransactionHistory,
  addWalletTransaction,
} = walletServices;

export default {
  namespaced: true,
  state: () => ({
    currentCompanyWallet: null,
  }),
  getters: {
    currentCompanyWallet: ({ currentCompanyWallet }) => currentCompanyWallet,
  },
  mutations: {
    SET_COMPANY_WALLET: (state, data) => {
      state.currentCompanyWallet = data;
    },
  },
  actions: {
    async getWalletHistory({ dispatch }, payload) {
      try {
        const { data } = await getWalletTransactionHistory(
          payload.walletId,
          payload.query?.search || ''
        );

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async exportWalletTransactions({ dispatch }, payload) {
      try {
        const data = await exportWalletTransactionHistory(
          payload.EndAt,
          payload.Format,
          payload.StartAt,
          payload.Search,
          payload.walletId
        );

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async makeCreditOrDebitTransaction({ dispatch }, payload) {
      try {
        const { data, status } = await addWalletTransaction(payload);

        if (status === 201) {
          dispatch(
            'notifications/displayNotification',
            {
              message: 'Transaction initiated',
              type: 'success',
            },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
  },
};
