<template>
  <div>
    <p class="mb-2 mt-4">Search with:</p>
    <div>
      <gl-form-radio
        @change="setSearchParameter"
        v-model="searchTypeSelected"
        value="companyName"
        >Company name
      </gl-form-radio>
      <gl-form-radio
        @change="setSearchParameter"
        v-model="searchTypeSelected"
        value="vendorId"
        >Vendor ID
      </gl-form-radio>
    </div>
    <gl-form-group
      id="business-name-group"
      :label="searchParameter.label"
      label-for="business-name"
      class="mb-4 mt-3"
    >
      <gl-form-combobox
        :token-list="formattedCompaniesList"
        label-text=""
        v-model="companyNameOrVendorId"
        :placeholder="searchParameter.placeholder"
        @value-selected="selectedCompany"
      />
    </gl-form-group>
  </div>
</template>

<script>
import { GlFormGroup, GlFormRadio, GlFormCombobox } from '@gitlab/ui';

export default {
  name: 'GetWalletId',
  /**
   * The purpose of this component is to handle the
   *  search for companies by vendorId or company name
   * Since it's to be used across three places, it just
   * made sense to make it a component
   * */
  components: {
    GlFormGroup,
    GlFormRadio,
    GlFormCombobox,
  },
  data: () => ({
    companyNameOrVendorId: '',
    searchTypeSelected: 'companyName',
    searchParameter: {
      label: 'Company name',
      placeholder: 'Enter company name',
    },
    fullCompaniesList: [],
    formattedCompaniesList: [],
  }),
  methods: {
    toggleLoading(isLoading) {
      this.$store.dispatch('loading/toggleLoading', isLoading);
    },
    setSearchParameter(value) {
      if (value === 'vendorId') {
        this.searchParameter.label = 'Vendor ID';
        this.searchParameter.placeholder = 'Enter vendor ID';
      } else {
        this.searchParameter.label = 'Company name';
        this.searchParameter.placeholder = 'Enter company name';
      }
    },
    async getAllCompanies() {
      this.toggleLoading(true);
      const response = await this.$store.dispatch('companies/getCompaniesLite');
      this.processCompaniesList(response);
      this.toggleLoading(false);
    },

    processCompaniesList(companies) {
      if (companies.length > 0) {
        const companiesWithWalletIds = companies.filter(
          (company) => company.walletId !== null
        );

        this.fullCompaniesList = companiesWithWalletIds;
        this.formattedCompaniesList = companiesWithWalletIds
          .map((company) => `${company.id}-${company.name}`)
          .filter(Boolean);
      }
    },

    selectedCompany(value) {
      const selectedBusinessId = value.split('-')[0];
      const walletId = this.fullCompaniesList.find(
        (company) => company.id === +selectedBusinessId
      )?.walletId;
      this.$emit('wallet-id', walletId);
    },
  },
  beforeMount() {
    this.getAllCompanies();
  },
};
</script>

<style></style>
