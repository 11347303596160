import { loanServices } from '@/services';
import { getCommisionStats } from '@/services/loanServices';

const {
  getLoanList,
  getLoansAwaitingAction,
  getLoan,
  getLoanDocuments,
  getLoansSummary,
  getLoanRequestLogs,
  addNoteToLoanLog,
  getLoanTransactions,
  getLoanRepayments,
  acceptLoanForProcessing,
  declineLoanRequest,
  updateLoanSettings,
  initiateLoanRestructure,
  initiateLoanAmendment,
  preApproveLoanRequest,
  approveLoanRequest,
  sendForCGOApproval,
  sendForCROApproval,
  sendForCFOApproval,
  makeWalletDisbursement,
  makeSupplierBankLoanDisbursement,
  makeCustomerBankLoanDisbursement,
  makeWalletRepayment,
  makeBankLoanRepayment,
  getOtherLoans,
  getOtherLoanDetails,
  createWCLoanRequest,
  createLFLoanRequest,
  createIDFLoanRequest,
  createLPOLoanRequest,
  resendLoanOffer,
  passAnOtherLoan,
  uploadLoanDocument,
  submitLoanRequest,
  archiveOrUnarchiveLoanRequest,
  addLoanExtension,
  getExtensionRequest,
  getCFOApproval,
  getCROApproval,
  approveLoanExtensionRequest,
  rejectLoanExtensionRequest,
  activateLoan,
  hasPendingExtensionRequest,
  retrieveLoanDetailsWithLoanId,
  getAmendmentRequest,
  getCGOApprovalForLoanAmendmentRequest,
  getCFOApprovalForLoanAmendmentRequest,
  approveLoanAmendmentRequest,
  declineLoanAmendmentRequest,
  getKongaLoanList,
  getKongaCommisionsToProcess,
} = loanServices;

export default {
  namespaced: true,
  state: () => ({
    loanList: [],
    loanTransaction: {},
    loanRepayments: {},
    loanRequest: {},
    otherLoanInfo: {},
  }),
  getters: {
    loanList: ({ loanList }) => loanList,
    loanTransaction: ({ loanTransaction }) => loanTransaction,
    loanRepayments: ({ loanRepayments }) => loanRepayments,
    loanRequest: ({ loanRequest }) => loanRequest,
    otherLoanInfo: ({ otherLoanInfo }) => otherLoanInfo,
  },
  mutations: {
    SET_LOAN_LIST: (state, data) => {
      state.loanList = data;
    },
    SET_LOAN_TRANSACTION: (state, data) => {
      state.loanTransaction = data;
    },
    SET_LOAN_REPAYMENTS: (state, data) => {
      state.loanRepayments = data;
    },
    SET_LOAN_REQUEST: (state, data) => {
      state.loanRequest = data;
    },
    SET_OTHER_LOAN_INFO: (state, data) => {
      state.otherLoanInfo = data;
    },
    CLEAR_OTHER_LOAN_INFO: (state) => {
      state.otherLoanInfo = {};
    },
  },
  actions: {
    async getLoanList({ commit, dispatch }, query) {
      try {
        const { data } = await getLoanList(
          query?.page || 1,
          query?.pageSize || 10,
          query?.search || '',
          query?.loanType || '',
          query?.loanRequestStatus || '',
          query?.loanStatus || '',
          query?.fromAmount || '',
          query?.toAmount || '',
          query?.maturityStartAt || '',
          query?.maturityEndAt || '',
          query?.createdStartAt || '',
          query?.createdEndAt || '',
          query?.sortBy || '',
          query?.orderBy || ''
        );

        commit('SET_LOAN_LIST', data.data);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getLoansAwaitingAction({ dispatch }, query) {
      try {
        const { data } = await getLoansAwaitingAction(
          query?.page || 1,
          query?.pageSize || 10,
          query?.search || ''
        );

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getLoansSummary({ dispatch }) {
      try {
        const { data } = await getLoansSummary();
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getLoan({ commit, dispatch }, id) {
      try {
        const { data } = await getLoan(id);

        commit('SET_LOAN_REQUEST', data);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getOtherLoans({ dispatch }, query) {
      try {
        const { data } = await getOtherLoans(
          query?.page || 1,
          query?.pageSize || 10,
          query?.search || '',
          query?.loanRequestStatus || '',
          query?.createdStartAt || '',
          query?.createdEndAt || '',
          query?.sortBy || '',
          query?.orderBy || ''
        );

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getOtherLoanDetails({ dispatch }, loanRequestId) {
      try {
        const { data } = await getOtherLoanDetails(loanRequestId);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async passAnOtherLoan({ dispatch }, loanRequestId) {
      try {
        const data = await passAnOtherLoan(loanRequestId);
        if (data.status === 204) {
          dispatch(
            'notifications/displayNotification',
            { message: `Loan request passed`, type: 'success' },
            { root: true }
          );
        }
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getLoanDocuments({ dispatch }, id) {
      try {
        const { data } = await getLoanDocuments(id);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getLoanLogs({ dispatch }, id) {
      try {
        const { data } = await getLoanRequestLogs(id);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async addLoanNote({ dispatch }, loanNote) {
      try {
        const data = await addNoteToLoanLog(loanNote.id, {
          content: loanNote.content,
        });

        if (data.status === 201) {
          dispatch(
            'notifications/displayNotification',
            { message: `Note added successfully`, type: 'success' },
            { root: true }
          );
        }
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getLoanTransactions({ dispatch }, loanId) {
      try {
        const { data } = await getLoanTransactions(loanId);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getLoanRepayments({ dispatch }, loanId) {
      try {
        const { data } = await getLoanRepayments(loanId);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async acceptLoanForProcessing({ dispatch }, loanRequestId) {
      try {
        const data = await acceptLoanForProcessing(loanRequestId);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan accepted for processing', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async resendLoanOffer({ dispatch }, loanRequestId) {
      try {
        const data = await resendLoanOffer(loanRequestId);

        if (data.status === 204) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan offer resent', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    //  loan extension request =============================
    async addLoanExtension({ dispatch }, extensionDetail) {
      try {
        const response = await addLoanExtension(extensionDetail);

        if (response.status === 200) {
          dispatch(
            'notifications/displayNotification',
            {
              message: 'Loan extension initiated successfully',
              type: 'success',
            },
            { root: true }
          );
        }
        return response.data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async getExtensionRequest({ dispatch }, payload) {
      try {
        const { data } = await getExtensionRequest(
          payload.loanId,
          payload.extensionRequestId
        );
        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan Extension Initiated', type: 'success' },
            { root: true }
          );
        }
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async getCFOApproval({ dispatch }, payload) {
      try {
        const data = await getCFOApproval(
          payload.loanId,
          payload.extensionRequestId
        );

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Request  sent to CFO', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCROApproval({ dispatch }, payload) {
      try {
        const data = await getCROApproval(
          payload.loanId,
          payload.extensionRequestId
        );

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Request sent to CRO', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async approveLoanExtensionRequest({ dispatch }, payload) {
      try {
        const data = await approveLoanExtensionRequest(
          payload.loanId,
          payload.extensionRequestId
        );

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan  extension request  approved ', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async rejectLoanExtensionRequest({ dispatch }, payload) {
      try {
        const data = await rejectLoanExtensionRequest(
          payload.loanId,
          payload.extensionRequestId,
          payload.reason
        );

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan  extension request declined', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    // handle pending extension
    async hasPendingExtensionRequest({ dispatch }, loanId) {
      try {
        const data = await hasPendingExtensionRequest(loanId);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async retrieveLoanDetailsWithLoanId({ dispatch }, loanId) {
      try {
        const { data } = await retrieveLoanDetailsWithLoanId(loanId);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    // loan request decline
    async declineLoanRequest({ dispatch }, payload) {
      try {
        const data = await declineLoanRequest(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan request declined', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async initiateLoanRestructuring({ dispatch }, payload) {
      try {
        const data = await initiateLoanRestructure(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan restructure initiated', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async initiateLoanAmendment({ dispatch }, payload) {
      try {
        const data = await initiateLoanAmendment(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan amendment initiated', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async getAmendmentRequest({ dispatch }, payload) {
      try {
        const data = await getAmendmentRequest(
          payload.loanRequestId,
          payload.amendmentRequestId
        );

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async getCFOApprovalForLoanAmendmentRequest({ dispatch }, payload) {
      try {
        const data = await getCFOApprovalForLoanAmendmentRequest(
          payload.loanRequestId,
          payload.amendmentRequestId
        );
        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Sent  for CFO  approval', type: 'success' },
            { root: true }
          );
        }
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async approveLoanAmendmentRequest({ dispatch }, payload) {
      try {
        const data = await approveLoanAmendmentRequest(
          payload.loanRequestId,
          payload.amendmentRequestId
        );
        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan amendment approved ', type: 'success' },
            { root: true }
          );
        }
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async getCGOApprovalForLoanAmendmentRequest({ dispatch }, payload) {
      try {
        const data = await getCGOApprovalForLoanAmendmentRequest(
          payload.loanRequestId,
          payload.amendmentRequestId
        );
        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Sent for CRO approval ', type: 'success' },
            { root: true }
          );
        }
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async declineLoanAmendmentRequest({ dispatch }, payload) {
      try {
        const data = await declineLoanAmendmentRequest(
          payload.loanRequestId,
          payload.amendmentRequestId,
          payload.reason
        );
        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan amendment declined', type: 'success' },
            { root: true }
          );
        }
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async updateLoanSettings({ dispatch }, payload) {
      try {
        const data = await updateLoanSettings(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan settings updated', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async preApproveLoanRequest({ dispatch }, loanRequestId) {
      try {
        const data = await preApproveLoanRequest(loanRequestId);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan request pre-approved', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async approveLoanRequest({ dispatch }, loanRequestId) {
      try {
        const data = await approveLoanRequest(loanRequestId);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Approval successful', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async sendForCGOApproval({ dispatch }, loanRequestId) {
      try {
        const data = await sendForCGOApproval(loanRequestId);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Request sent to CGO', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async sendForCROApproval({ dispatch }, loanRequestId) {
      try {
        const data = await sendForCROApproval(loanRequestId);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Request sent to CRO', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async sendForCFOApproval({ dispatch }, loanRequestId) {
      try {
        const data = await sendForCFOApproval(loanRequestId);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Request sent to CFO', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async activateLoanRestructure({ dispatch }, payload) {
      try {
        const data = await activateLoan(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan activated', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async makeWalletDisbursement({ dispatch }, payload) {
      try {
        const data = await makeWalletDisbursement(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Disbursement completed', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async makeSupplierBankLoanDisbursement({ dispatch }, payload) {
      try {
        const data = await makeSupplierBankLoanDisbursement(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Disbursement completed', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async makeCustomerBankLoanDisbursement({ dispatch }, payload) {
      try {
        const data = await makeCustomerBankLoanDisbursement(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Disbursement completed', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async makeWalletRepayment({ dispatch }, payload) {
      try {
        const response = await makeWalletRepayment(payload);

        if (response.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Repayment completed', type: 'success' },
            { root: true }
          );
        }

        return response;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async makeBankLoanRepayment({ dispatch }, payload) {
      try {
        const data = await makeBankLoanRepayment(payload);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Repayment completed', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async createWCLoanRequest({ dispatch }, payload) {
      try {
        const data = await createWCLoanRequest(payload);

        if (data.status === 201) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan request successful', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async createLFLoanRequest({ dispatch }, payload) {
      try {
        const data = await createLFLoanRequest(payload);

        if (data.status === 201) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan request successful', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async createIDFLoanRequest({ dispatch }, payload) {
      try {
        const data = await createIDFLoanRequest(payload);

        if (data.status === 201) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan request successful', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async createLPOLoanRequest({ dispatch }, payload) {
      try {
        const data = await createLPOLoanRequest(payload);

        if (data.status === 201) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Loan request successful', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async uploadLoanDocument({ dispatch }, { loanRequestId, document }) {
      try {
        const data = await uploadLoanDocument(loanRequestId, document);

        if (data.status === 201) {
          dispatch(
            'notifications/displayNotification',
            {
              message: 'Document upload successful',
              type: 'success',
            },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async submitLoanRequest({ dispatch }, loanRequestId) {
      try {
        const data = await submitLoanRequest(loanRequestId);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            {
              message: 'Loan request submitted successfully',
              type: 'success',
            },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async archiveOrUnarchiveRequest({ dispatch }, payload) {
      try {
        const data = await archiveOrUnarchiveLoanRequest(
          payload.loanRequestId,
          payload.type
        );

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            {
              message: `Loan request ${payload.type}d successfully`,
              type: 'success',
            },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getKongaLoanList({ dispatch }) {
      try {
        const { data } = await getKongaLoanList();
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCommisionStats({ dispatch }) {
      try {
        const { data } = await getCommisionStats();
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getKongaCommisionsToProcess({ dispatch }) {
      try {
        const { data } = await getKongaCommisionsToProcess();
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
  },
};
